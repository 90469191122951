export const paddingSliderDefaults = { 
	min: 0, 
	max: {px: 300, rem: 20, em: 20, vw: 20, vmin: 20, vmax: 20, vh: 20, '%': 30, etc: 100},
	step: {rem: 0.1, em: 0.1, '%': 0.1, vw: 0.1, vmin: 0.1, vmax: 0.1, vh: 0.1, etc: 1},
	allowCalc:false,
	allowOutOfRangeTextInput: false,
	addDefaultUnitToUnitlessNumber: true,
	defaultUnit: "rem"
}

export const dropshadowSliderDefaults = { 
	step: {rem: 0.1, em: 0.1, '%': 0.1, vw: 0.1, vmin: 0.1, vmax: 0.1, vh: 0.1, etc: 1},
	allowCalc:false,
	allowOutOfRangeTextInput: false,
	addDefaultUnitToUnitlessNumber: true,
	defaultUnit: "rem",
	cssTestProperty: 'margin',
}

export const insetSliderDefaults = { 
	min: 0, 
	// max: {px: 300, rem: 20, em: 20, vw: 20, vmin: 20, vmax: 20, vh: 20, '%': 30, etc: 100},
	step: {rem: 0.1, em: 0.1, '%': 0.1, vw: 0.1, vmin: 0.1, vmax: 0.1, vh: 0.1, etc: 1},
	allowCalc:false,
	allowOutOfRangeTextInput: false,
	addDefaultUnitToUnitlessNumber: true,
	defaultUnit: "rem"
}

export const blurSliderDefaults = { 
	min: 0, 
	// max: {px: 300, rem: 20, em: 20, vw: 20, vmin: 20, vmax: 20, vh: 20, '%': 30, etc: 100},
	step: {rem: 0.01, em: 0.01, '%': 0.01, vw: 0.01, vmin: 0.01, vmax: 0.01, vh: 0.01, etc: 0.1},
	allowCalc:false,
	allowOutOfRangeTextInput: false,
	addDefaultUnitToUnitlessNumber: true,
	defaultUnit: "rem"
}

export const widthSliderDefaults = {
	min: 0,
	max: {'%': 100},
	allowedUnits: ['%', 'rem', 'em', 'vw', 'vmin', 'vmax', 'vh', 'px'],
	step: {rem: 0.1, em: 0.1, '%': 1, vw: 1, vmin: 1, vmax: 1, vh: 1, etc: 1, px: 1},
	allowCalc: false,
	allowOutOfRangeTextInput: false,
	addDefaultUnitToUnitlessNumber: true,
	defaultUnit: '%'
}

export const fontSizeDefaults = {
	min: { px: 6, etc: 0.5 },
	allowOutOfRangeTextInput: false,
	addDefaultUnitToUnitlessNumber: true,
	defaultUnit: "rem"
}

export const lineHeightDefaults = {
	allowOutOfRangeTextInput: false,
	addDefaultUnitToUnitlessNumber: false,
	defaultUnit: "noUnit",
	min:{
		etc: 0,
		noUnit: .5
	},
	max:{
		noUnit: 10
	},
	step:{
		noUnit: 0.05
	},
	cssTestProperty: "line-height"
}

