import { wsProvider } from "../../lib/multi-user";
import { useEffect, useState } from 'react';

const getUploadProgressFromAwarenessStates = (hash) => {

    for (const state of wsProvider.awareness.getStates().values()) {
      if(state[`${hash}-progress`]) {
            return state[`${hash}-progress`];
        }
    }

    return 0;

}

const UploadProgressIndicator = ({ progress, hash, numerical=false, type="image" }) => {

    const [syncedProgress, setSyncedProgress] = useState(0);

    // if progress is not manually set, use the hash to try and pull upload
    // progress from websocket awareness states
    if(progress === undefined || progress === null && typeof hash === "string") {

        useEffect(() => {

            // get initial value
            setSyncedProgress(getUploadProgressFromAwarenessStates(hash));

            const changeListener = (changes) => {
                setSyncedProgress(getUploadProgressFromAwarenessStates(hash));
            }

            wsProvider.awareness.on('change', changeListener)

            return () => {
                wsProvider.awareness.off('change', changeListener)
            }

        }, [hash]);

        // override
        progress = syncedProgress;

    }

    if(isNaN(progress)) {
        progress = 0;
    }

    // just return the number
    if(numerical) {
        return progress;
    }

    let progressDone = progress >= 99 ? true : false;
    let uploadStatus = progressDone ? 'pending' : 'uploading';
    let dashArray = progressDone ? '3 4.50' : 157.59622192382812;
    let dashOffset = progressDone ? 0 : dashArray - Math.round((dashArray / 100) * progress);

    return (
        <div 
            className="upload-progress"
            type={type}
            upload-status={uploadStatus}
        >
            <svg className="progress-indicator" width="54px" height="54px" viewBox="0 0 54 54" >
                <g stroke="none" strokeWidth="0" fill="none" fillRule="evenodd">
                    <circle className="bg_circle" fill="none" stroke="var(--ui-color-flat)" strokeWidth="4" strokeOpacity="0.3" strokeMiterlimit="10" cx="27" cy="27" r="25"/>
                    <path 
                        d="M52,27 C52,13.1928806 40.8071194,2 27,2 C13.1928806,2 2,13.1928806 2,27 C2,40.8071194 11,52 27,52 C40.8071194,52 52,40.8071194 52,27 Z" 
                        stroke="var(--ui-color-flat)" 
                        strokeWidth="4" 
                        strokeDasharray={dashArray} 
                        strokeDashoffset={dashOffset} 
                        transform="translate(27.000000, 27.000000) rotate(90.000000) scale(-1, 1) translate(-27.000000, -27.000000) "></path>
                </g>
            </svg>
        </div>
    )
}

export default UploadProgressIndicator;

export {UploadProgressIndicator};