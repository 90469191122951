import { Fragment, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Alert, AlertContext } from "@cargo/ui-kit";
import { helpers } from "@cargo/common";

import Thumbnail from './thumbnail';
import { actions } from '../../actions';
import selectors from '../../selectors';
import { convertStateToSharedType } from "../../lib/multi-user/redux";
import globalDragEventController from "../drag-event-controller";
import { HotKey } from './hotkey';
import { getSupportedFileTypes } from './helpers';
import { restrictToParentElement } from '@dnd-kit/modifiers'
import {
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
  } from '@dnd-kit/core';
import {SortableContext, sortableKeyboardCoordinates, useSortable, arrayMove } from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { uploadMedia, deleteMedia, convertFileToImage } from '../../lib/media';
import { CRDTState } from '../../globals';
import UploadButton from './upload-button';
import LibraryButton from './library-button';
import FilesButton from './files-button';
import { HotKeyProxy } from './hotkey-proxy';
import { store } from '../../index';

class ImagePickerPointerSensor extends PointerSensor {
    static activators = [
      {
        eventName: 'onMouseDown',
        handler: ({nativeEvent: event}) => {
            if (
                event.isPrimary ||
                event.button !== 0 ||
                isInteractiveElement(event.target)
            ) {
                return false;
            }
            return true;
        },
      },
    ];
}

const isInteractiveElement = (element) => {
    const interactiveElements = [
      'button',
      'a',
    ];

    if (element.closest('button') || element.closest('a')) {
        return true;
    }
  
    return false;
}

const Sortable = ({id, disabled, children, style}) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id, disabled});

    const sortableStyle = {
        zIndex: attributes['aria-pressed'] === true ? 999 : 0,
        transform: CSS.Transform.toString(transform ? {x: transform.x, y: transform.y, scaleX: 1, scaleY: 1} : null),
        transition,
        ...style
    };
  
    return (
        <div ref={setNodeRef} className={attributes['aria-pressed'] === true ? 'sortable-dragging' : ''} style={sortableStyle} {...attributes} {...listeners}>
            {children}
        </div>
    );
}

const ImagePicker = ({
    addPadding = false,
    label = null,
    images = [], 
    selected = [], 
    onSelectedChange = null, 
    excluded = [], 
    onExcludedChange = null, 
    order = null,
    onOrderChange = null,
    scaledImageWidth = 68.5, 
    onRemove = null,
    removeOnHotKey = false,
    emptyMessage = 'Drop an image here',
    columns = 4,
    componentWidth = 310,
    allowDragOut = false,
    addUIWindow = null,
    removeUIWindow = null,
    showLibraryButton = true,
    clickoutLayer = false,
    onLibrarySelectedChange = null,
    showUploadButton = true,
    showFilesButton = false,
    showButtons = true,
    pageId = null,
    renderAsWindow = false,
    allowDuplicates = false,
    onDrop = null,
    requireConfirmation = false,
    sortable = false,
    showPlaceholder = false,

}) => {

    const [dragging, setDragging] = useState(null);
    const [dndGhostIndex, setDndGhostIndex] = useState(null);
    const [canRemove, setCanRemove] = useState(false);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [deleteSelected, setDeleteSelected] = useState(false);
    const [renderRange, setRenderRange] = useState([0,0]);
    const imagepickerRef = useRef();
    const scrollableParentRef = useRef();
    const prevImagesRef = useRef(images);
    const contentHeightRef = useRef(0);

    const horizontalPadding = 12;
    const verticalPadding = 12;
    let totalPadding = (columns - 1) * horizontalPadding;
    scaledImageWidth = (componentWidth - totalPadding) / columns;

    useEffect(() => {
        globalDragEventController.on('dragover', handleDragOver);
        globalDragEventController.on('drop', handleDrop);

        return function cleanup () {
            globalDragEventController.off('dragover', handleDragOver);
            globalDragEventController.off('drop', handleDrop);
        }
    }, [isDraggingOver, images])

    useEffect(() => {

        if(images !== prevImagesRef.current) {

            const added =  _.difference(images, prevImagesRef.current);

            // scroll up if a new image is being uploaded
            if(scrollableParentRef.current && added.some(img => img.loading)) {
                scrollableParentRef.current.scrollTop = 0;
            }

            prevImagesRef.current = images;
        }

        if (order !== null && images.length !== order.length && onOrderChange ) {
            onOrderChange(images.map(image => image.hash))
        }
    }, [images]);

    const sensors = useSensors(
        useSensor(ImagePickerPointerSensor)
    );

    const getAdjustedIndex = (index) => {
        return order !== null ? images.findIndex((i) => i.hash === order[index]) : index;
    }

    const handleSelect = (e, index) => {
        if (!onSelectedChange) {
            return;
        }
        if( (e.metaKey || e.shiftKey || e.ctrlKey ) ) {
            // if meta/shift while clicking a selected image, remove that image from selection
            if( selected.includes(index) ){
                const filteredArray = selected.filter(i => i !== index);
                onSelectedChange(filteredArray);
            } else {
                // meta key will simply add the image
                if( ( helpers.isMac() && e.metaKey ) || ( !helpers.isMac() && e.ctrlKey ) ){
                    onSelectedChange([...selected, index]);
                // shift will select a range of images
                } else {
                    // select all the images from the last selected image in the array to the currently selected one
                    const selectedRange = getSelectedRange(index, lastSelectedIndex);
                    onSelectedChange([...new Set([...selected, ...selectedRange])]);
                }
            }
        } else {
            if (selected.length < 2 || selected.includes(index) === false) {
                onSelectedChange([getAdjustedIndex(index)]);
            }
        } 
        setLastSelectedIndex(index);
    }

    const getSelectedRange = (current, last) => {
        const start = current > last ? last : current;
        const end = current > last ? current : last;
        return Array(end - start + 1).fill().map((_, idx) => start + idx);
    }

    const calcImageHeight = ({width, height}) => {

        if(!width) {
            width = scaledImageWidth;
        }

        if(!height) {
            height = scaledImageWidth;
        }

        let aspectRatio;

        switch (true) {
            // portrait
            case width/height <= 0.6667: {
                aspectRatio = 1.5
                break;
            }
            // landscape
            case width/height >= 1.7778: {
                aspectRatio = 0.56
                break;
            }
            default: {
                aspectRatio = Math.round( 100 * (height/width) ) / 100
                break;
            }
        }

        // Calc image height and round to nearest hundreths 
        return Math.round( 100 * ( scaledImageWidth * aspectRatio ) ) / 100;
    }

    const handleDragEnd = (event) =>{

        const {active, over} = event;
        
        if (active && over && active.id !== over.id) {
            const oldIndex = order.indexOf(active.id);
            const newIndex = order.indexOf(over.id);
            onOrderChange(arrayMove(order, oldIndex, newIndex));
        }

        setDndGhostIndex(null) 
    }

    const handleDragOver = (editor, e, dragData) => {
        if ( onDrop === null || !e.toElement) {
            return;
        }
        let draggingOver = false;
        const toElement = e.toElement;
        if ( imagepickerRef.current && ( toElement.contains(imagepickerRef.current) || imagepickerRef.current.contains(toElement) || imagepickerRef.current === toElement ) ) {
            draggingOver = true;
        }
        // if ( imagepickerRef.current && imagepickerRef.current.contains(srcElement) ) {
        //     draggingOver = false;
        // }

        let isDuplicate = false;
        const draggedModels = dragData.dataTransfer?.get('image-models');
        if ( draggedModels && images.length > 0 && images.some((i) => i.hash === draggedModels[0].hash )) {
            isDuplicate = true;
        }
        //if ( isDuplicate === false || allowDuplicates === true ) {
            setIsDraggingOver(draggingOver);
        //}
    }

    const uploadImage = (files) => {
        files.forEach(async (file, i) => {
            if ( getSupportedFileTypes('image').includes(file.type) || getSupportedFileTypes('video').includes(file.type) ) {
                const mediaItem = document.createElement('media-item');
                file.onHash = hash => {
                    mediaItem.setAttribute('hash', hash);
                    if( onDrop){
                        onDrop(mediaItem);
                    }                   
                }
                uploadMedia({
                    target: getCRDTItem({ reducer: 'pages.byId', item: pageId }),
                    field: 'media'
                }, [file])[0]
                .then(uploadedImage => {
                    console.log('uploaded', uploadedImage);
                    onSelectedChange?.([images.length]);
                })
                .catch(err => {
                    console.error(err);
                });
            } else {
                uploadMedia({
                    target: getCRDTItem({ reducer: 'media' }),
                    field: 'data'
                }, [file])[0]
                .then(uploadedFile => {
                    console.log('uploaded file', file)
                })
                .catch(err => {
                    console.error(err);
                });
            }
        })
    }

    const handleDrop = async (editor, e, dragData) => {

        if (onDrop === null || isDraggingOver === false || e.target.tagName== 'BODY' || ( !imagepickerRef.current.contains(e.target) && !e.target.contains(imagepickerRef.current) ) ) {
            return;
        }
        if (dragData.inAdmin && dragData.fromOutside) {
            const droppedFiles = dragData.dataTransfer.get('files');
            droppedFiles.forEach(async (file) => {
                if ( getSupportedFileTypes('image').includes(file.type) || getSupportedFileTypes('video').includes(file.type) ) {
                    uploadImage([file]);
                }
            })
        }


        if ( dragData.fromAdmin === true && dragData.inAdmin === true && (e.srcElement.contains(imagepickerRef.current) || imagepickerRef.current.contains(e.srcElement)) ) {
            dragData.draggedMediaItems.forEach((node) => {
                const hash = node.getAttribute('hash');
                const globalImages = _.uniqBy(Object.values(selectors.getMediaByHash(store.getState())), 'id');
                const model = globalImages.find((i) => i.hash === hash);
                maybeAddModelToPage(model);
                if(onDrop){
                    onDrop(node);
                }
            })              
        }
        setIsDraggingOver(false);
    }

    const onDragStart = (e, index)=>{

        // backdrop-style image picker can't be used to drag media to pages
        if(allowDragOut === false){
            return;
        }

        setDragging(index);

        let draggedMedia = selected.map((i) => images[i]);

        let closestThumb = e.currentTarget.closest('[data-image-src]');
        if( e.target.hasAttribute('data-image-src')){
            let blankURI = e.target.src;
            let thumbImg = e.target.cloneNode();
            const rect = e.target.getBoundingClientRect();

            thumbImg.width = rect.width*2;
            thumbImg.height = rect.height*2;

            thumbImg.style.width = rect.width+'px'
            thumbImg.style.height = rect.height+'px'
            thumbImg.style.position = 'fixed';
            thumbImg.style.top = 0;
            thumbImg.style.left = 0;
            thumbImg.style.zIndex = -1;   
            thumbImg.style.backgroundSize = 'cover';
            thumbImg.style.backgroundRepeat = 'no-repeat';

            thumbImg.src = e.target.getAttribute('data-image-src');

            document.body.appendChild(thumbImg);

            // Safari and Firefox have the correct behavior here.
            // Chrome makes us double up the size (presumably because the element's dimensions are halved)
            // Chrome appears to have fixed this so we are disabling for now
            if( !helpers.isSafari() && !helpers.isFirefox() && !window.devicePixelRatio ===1 ){
                e.dataTransfer.setDragImage(thumbImg, (e.clientX + -rect.left + 5) * 2  , (e.clientY + -rect.top + 5) * 2 )
            }

            setTimeout(()=>{
                thumbImg.remove();
            }, 120)
        }

        // if we start dragging an unselected image, select it 
        if( draggedMedia.length == 0 || !selected.includes(index) ){
            draggedMedia = [images[index]];
            onSelectedChange?.([index]);
        }

        const draggedNodes = draggedMedia.map(mediaModel=>{

            const mediaItem = document.createElement('media-item');
            mediaItem.setAttribute('hash', mediaModel.hash);

            if( mediaModel.is_video ){
                if(mediaModel.has_audio_track || mediaModel.duration > 15 ){
                    mediaItem.setAttribute('browser-default', true);
                    mediaItem.setAttribute('disable-zoom', true);                    
                } else {
                    mediaItem.setAttribute('autoplay', true);
                    mediaItem.setAttribute('muted', true);
                    mediaItem.setAttribute('loop', true);
                }
            }  else if (  (mediaModel.url_type == 'video' || mediaModel.url_type == 'youtube' || mediaModel.url_type == 'vimeo') ){
                mediaItem.setAttribute('browser-default', true);
                mediaItem.setAttribute('disable-zoom', true);

            }    
		
			return mediaItem;

        });

        // set image models and nodes for dragging into frontend
        globalDragEventController.once('dragstart', (editor, e, data)=>{
            data.dataTransfer.set('image-models', draggedMedia);
            globalDragEventController.setDraggedNodes(draggedNodes);
        })

    }

    const onDragEnd = (e) => {
        setDragging(null);
        onSelectedChange?.([]);
    }

    // images do not necessarily have an order (example: image uploaded outside of backdrop picker)
    // so if they do not have an order, just stack them at the beginning
    // and re-make the order array according to that
    const orderedImages = useMemo(() => {

        const result = [...images];
        
        if (order !== null) {
            result.sort((a,b) => order.indexOf(a.hash) < order.indexOf(b.hash) ? -1 : 1);
        }

        return result

    }, [images, order]);

    const thumbnailsJSX = useMemo(() => {

        const hashCounter = {}

        const [minTop, maxTop] = renderRange;
        const rows = _.chunk(orderedImages, columns);
        // row height = tallest image + vertical gutter and exclude gutter on the last row
        const rowHeights = rows.map((images, i) => 
            Math.max(...images.map(img => 
                calcImageHeight(img) + (i === rows.length - 1 ? 0 : verticalPadding)
            ))
        ); 

        // add top + bottom padding if addPadding is true
        const totalHeight = rowHeights.reduce((acc, rowHeight) => acc + rowHeight, 0) + (addPadding ? verticalPadding * 2 : 0);

        // add top padding
        let top = addPadding ? verticalPadding : 0;

        const rowsJSX = rows.map((rowImages, rowIndex) => {

            const rowIsOutOfView = top < minTop || top > maxTop;

            // reset left every row start
            let left = addPadding ? horizontalPadding : 0;

            const rowJSX = rowImages.map((image, index) => {

                const absoluteImageIndex = (rowIndex * columns) + index;

                // don't render this image if it's row is out of view. However
                // if this image is being dragged we need to render it at all times
                if(rowIsOutOfView && absoluteImageIndex !== dndGhostIndex) {
                    return null;
                }
                
                const height = calcImageHeight( image );
                const thumbStyle = { 
                    width: scaledImageWidth, 
                    height: height
                };

                const rowStyle = {
                    ...thumbStyle,
                    position: 'absolute',
                    left,
                    top
                }

                // shift over the image into the correct spot in it's row
                left += scaledImageWidth + horizontalPadding;

                if ( excluded.includes(image.hash) ) {
                    thumbStyle.opacity = 0.25;
                }

                let apiRequestWidth = scaledImageWidth ? scaledImageWidth : image.width;
                apiRequestWidth = apiRequestWidth * 2;
                if( Number.isNaN(apiRequestWidth) || !apiRequestWidth ){
                    apiRequestWidth = 100;
                }
                apiRequestWidth = Math.round(apiRequestWidth);

                hashCounter[image.hash] = hashCounter[image.hash] === undefined ? 0 : ++hashCounter[image.hash];

                return (
                    <Sortable 
                        key={image.hash + '/' + hashCounter[image.hash]} 
                        id={image.hash} 
                        disabled={!sortable}
                        style={rowStyle}
                    >
                        <Thumbnail 
                            media={image}
                            width={apiRequestWidth}
                            style={thumbStyle}
                            onSelect={(e) => handleSelect(e, absoluteImageIndex)}
                            isSelected={selected.includes(getAdjustedIndex(absoluteImageIndex))}
                            onDragStart={e => onDragStart(e, absoluteImageIndex)}
                            onDragEnd={e => onDragEnd(e)}
                            isDraggable={allowDragOut}
                        >
                            {onExcludedChange && images.length > 1
                                ? excluded.includes(image.hash) 
                                    ? <button 
                                        className="add"
                                        onClick={() => {
                                            onExcludedChange(excluded.filter(val => val !== image.hash))}
                                        }
                                    ><span>+</span></button> 
                                    : <button 
                                        className="remove"
                                        onClick={() => {
                                            const arr = excluded.concat([image.hash]);
                                            onExcludedChange(arr)
                                        }}
                                    ><span>-</span></button> 
                                : null}
                            <a 
                                href={image.is_url ? image.url : `https://freight.cargo.site/t/original/i/${image.hash}/${image.name}`} 
                                className="open-new-window" 
                                target="_blank" 
                            >
                                ↗
                            </a>      
                            {onRemove ? (
                                <AlertContext.Consumer>
                                    {(Alert) => (
                                        <button 
                                            className="delete"
                                            onClick={(e) => requireConfirmation 
                                                ? Alert.openModal({
                                                    header: 'Delete image?',
                                                    message: 'Deleted images cannot be recovered',
                                                    type: 'confirm',
                                                    HotKeyProxy: HotKeyProxy,
                                                    onConfirm: (options) => {
                                                        e.stopPropagation();
                                                        onRemove([absoluteImageIndex])
                                                        onSelectedChange?.([])
                                                    },
                                                    onDeny: (options) => {
                                                    }
                                                })
                                                : onRemove([absoluteImageIndex])}
                                        >
                                            ×
                                        </button>
                                    )}
                                </AlertContext.Consumer>
                            ) : null}
                        </Thumbnail>
                    </Sortable>
                )
            });

            // add row height to total height at end of row
            top += rowHeights[rowIndex];

            return <Fragment key={rowIndex}>
                {rowJSX}
            </Fragment>

        })

        return <div className="image-picker" style={{height: totalHeight}}>
            {rowsJSX}
        </div>

    }, [orderedImages, selected, excluded, renderRange, dndGhostIndex]);

    const calculateRenderRange = scrollableParent => {

        const offset = scrollableParent.scrollTop;

        // render one height above and below for a total
        // of 3 heights total
        setRenderRange([
            offset - (contentHeightRef.current),
            offset + (contentHeightRef.current * 2)
        ])

    }

    const handleScroll = e => {
        calculateRenderRange(e.target);
    }

    useEffect(() => {

        // find nearest scrollable parent
        let scrollableParent = imagepickerRef.current;
        while (scrollableParent) {
            const { overflow } = window.getComputedStyle(scrollableParent);
            if (overflow.includes('scroll') || overflow.includes('auto')) {
              break;
            }
            scrollableParent = scrollableParent.parentElement;
        }

        scrollableParentRef.current = scrollableParent;

        const resizeObserver = new ResizeObserver((entries) => {
            contentHeightRef.current = entries[0].contentRect.height
            calculateRenderRange(scrollableParent)
        });
        
        resizeObserver.observe(scrollableParent);

        scrollableParent.addEventListener('scroll', handleScroll, {
            passive: true
        });

        return () => {

            scrollableParent.removeEventListener('scroll', handleScroll);
            resizeObserver.unobserve(scrollableParent);
            resizeObserver.disconnect();

        };

    }, [imagepickerRef]);


    if( showPlaceholder){
        orderedImages.push({
            file_size : 0,
            file_type : "png",
            hash : "placeholder",
            width: 1600,
            height : 1000,
            id : 0,
            in_use : true,
            is_image : true,
            is_video : false,
            mime_type : "image/png",
            name : "placeholder.png",
            created_at : "2023-05-30T23:41:23.469785Z",            
            updated_at : "2023-05-30T23:41:23.469785Z"
        })        
    }

    useEffect(() => {
        const handleClick = (e) => {
            if (!e.target.closest('.thumbnail')) {
                setCanRemove(false);
            } else {
                setCanRemove(true);
            }
            if (imagepickerRef.current !== null && onSelectedChange !== null && !e.target.closest('.thumbnail')) {
                onSelectedChange([]);
            }
        }
        window.addEventListener('click', handleClick)
        return function() {
            window.removeEventListener('click', handleClick)
        }
    }, [imagepickerRef, onSelectedChange]);

    const maybeAddModelToPage = (model) => {
        if (!model) {
            return;
        }
        const localImages = selectors.getMediaByParent(store.getState())[pageId]
        const existingHashes = localImages.reduce((prev, curr) => {
            return [...prev, curr.hash]
        }, [])
        let isNew = !existingHashes.includes(model.hash);

        if (isNew) {
            
            const { CRDTItem: pageCRDT } = getCRDTItem({
                reducer: "pages.byId",
                item: pageId
            });
            
            // If the images for the page aren't in a CRDT, create a new Y Array to store them
            if (pageCRDT.get("media") instanceof Y.Array === false) {
                const sharedMediaType = new Y.Array();
                sharedMediaType.insert(0, pageCRDT.get("media") || []);
                pageCRDT.set("media", sharedMediaType);
            }

            // convert plain model to shared type
            model = convertStateToSharedType(model, new Y.Map());

            if(model instanceof Y.Map) {
                pageCRDT.get('media').push([model]);
            }

        }
    }

    useEffect(() => {
        if ( selected.length > 0 && deleteSelected === true && onRemove ) {
            onRemove(selected);
            setDeleteSelected(false);
        }
    }, [deleteSelected, selected])

    const handleHotKey = () => {
        setDeleteSelected(true);
    }

    return (
        <div className={`ui-group${isDraggingOver ? ' draggingover' : ''}`} ref={imagepickerRef}>
            {removeOnHotKey === true && selected.length > 0 && document.activeElement.tagName !== "INPUT" ? (
                <AlertContext.Consumer>
                    {(Alert) => (
                        <HotKey 
                            shortcut="delete" 
                            boundTo="admin"
                            callback={(e) => {
                                if ( requireConfirmation ) {
                                    Alert.openModal({
                                        header: 'Delete image?',
                                        message: 'Deleted images cannot be recovered',
                                        type: 'confirm',
                                        HotKeyProxy: HotKeyProxy,
                                        onConfirm: (options) => {
                                            e.stopPropagation();
                                            handleHotKey();
                                        },
                                        onDeny: (options) => {
                                        }
                                    })
                                } else {
                                    handleHotKey();
                                }
                            }}
                        />
                    )}
                </AlertContext.Consumer>
            ) : null}
            {showButtons === true ? (
                <div className="button-row grid-columns-even">
                    {showUploadButton && (
                        <div>
                            <UploadButton
                                pageId={pageId}
                                onDrop={(val) => onDrop(val)}
                                onUpload={onSelectedChange ? (val) => onSelectedChange([images.length]) : null}
                            />
                        </div>
                    )}
                    {showLibraryButton && (
                        <div>
                            <LibraryButton
                                // clickoutLayer={clickoutLayer}
                                // onSelectedChange={(val) => {
                                //     const model = val[0];
                                //     if ( !model ) {
                                //         return;
                                //     }
                                //     maybeAddModelToPage(model);
                                //     if ( onDrop !== null ) {
                                //         const mediaItem = document.createElement('media-item');
                                //         mediaItem.setAttribute('hash', model.hash);
                                //         onDrop(mediaItem);
                                //     }
                                // }}
                            />
                        </div>
                    )}
                    {showFilesButton && (
                        <div>
                            <FilesButton/>
                        </div>
                    )}
                </div>
            ) : null}
            {orderedImages.length > 0 ? (
                <DndContext sensors={sensors} onDragStart={({active}) => { setDndGhostIndex(active.data.current.sortable.index) }} onDragEnd={(e) => onOrderChange && handleDragEnd(e)}>
                    <SortableContext items={order !== null ? order : orderedImages.map(i => i.hash)}>
                        {thumbnailsJSX}
                    </SortableContext>
                </DndContext>
            ) : (
                <div className="image-picker image-picker--empty">
                    <div className="image-picker__icon">
                        <svg width="59" height="74" viewBox="0 0 59 74" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M58 73H1V1H43.5V15.5H58V73ZM59 14.5V15.5V74H0V0H43.5H44.5L59 14.5ZM44.5 1.41406L57.5859 14.5H44.5V1.41406ZM13 49L22.3711 36.0058L25.5273 40.696L34.708 28.0705L48 49H13ZM25.4951 42.4401L34.6514 29.8478L46.1807 48H14.9541L22.3428 37.7548L25.4951 42.4401ZM22.375 31.75C24.2393 31.75 25.75 30.239 25.75 28.375C25.75 26.511 24.2393 25 22.375 25C20.5107 25 19 26.511 19 28.375C19 30.239 20.5107 31.75 22.375 31.75ZM22.375 32.75C24.791 32.75 26.75 30.7913 26.75 28.375C26.75 25.9587 24.791 24 22.375 24C19.959 24 18 25.9587 18 28.375C18 30.7913 19.959 32.75 22.375 32.75Z"/>
                        </svg>
                    </div>
                    <div className="image-picker__message">
                        <span>{emptyMessage}</span>
                    </div>
                </div>
            )}
        </div>
    )}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addUIWindow: actions.addUIWindow,
        updateUIWindow: actions.updateUIWindow,
        removeUIWindow: actions.removeUIWindow,
    }, dispatch);

}

function mapReduxStateToProps(state, ownProps) {

    return {
        pageId: ownProps.pageId ? ownProps.pageId : state.frontendState.PIDBeingEdited
    };
}

export default connect(
    mapReduxStateToProps,
    mapDispatchToProps
)(ImagePicker);

//export {ImagePicker};
