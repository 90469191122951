import bold from "./commands/bold";
import underline from "./commands/underline";
import small from "./commands/small";
import italic from "./commands/italic";
import alignleft from "./commands/alignleft";
import aligncenter from "./commands/aligncenter";
import alignright from "./commands/alignright";
import h1 from "./commands/h1";
import h2 from "./commands/h2";
import hr from "./commands/hr";
import blockquote from "./commands/blockquote";
import link from "./commands/link";
import textcolor from "./commands/textcolor";
import backcolor from "./commands/backcolor";
import smallcaps from "./commands/smallcaps";
import lineatetext from "./commands/lineatetext"
import superscript from "./commands/superscript";
import subscript from "./commands/subscript";
import unorderedlist from "./commands/unorderedlist";
import orderedlist from "./commands/orderedlist";
import marquee from "./commands/marquee";
import flyingobject from "./commands/flyingobject";
import audioplayer from "./commands/audioplayer";
import commerce from "./commands/commerce";
import imageoptions from "./commands/imageoptions";
import imagegallery from "./commands/imagegallery";
import thumbnailindex from "./commands/thumbnailindex";
import columns from "./commands/columns";
import iconpalette from "./commands/iconpalette";
import textstyles from "./commands/textstyles";
import fontsize from "./commands/fontsize";
import lineheight from "./commands/lineheight";
import textoptions from "./commands/textoptions";
import textformatting from "./commands/textformatting";
import textalignment from "./commands/textalignment";
import layouttemplate from "./commands/layouttemplate";
import mediaoptions from './commands/mediaoptions';
import pageoptions from './commands/pageoptions';
import uses from './commands/uses';
import strikethrough from './commands/strikethrough';
import nowrap from './commands/nowrap';
import loremipsum from './commands/loremipsum';
import border from "./commands/border";
import dropshadow from "./commands/dropshadow";
import digitalclock from "./commands/digitalclock";

import insertwindow from './commands/insertwindow';

export const commands = {
	bold,
	underline,
	small,
	italic,
	alignleft,
	aligncenter,
	alignright,
	h1,
	h2,
	hr,
	blockquote,
	orderedlist,
	unorderedlist,
	smallcaps,
	lineatetext,
	subscript,
	superscript,
	textcolor,
	backcolor,
	link,
	marquee,
	flyingobject,
	audioplayer,
	commerce,
	imageoptions,
	imagegallery,
	thumbnailindex,
	columns,
	iconpalette,
	textstyles,
	fontsize,
	lineheight,
	textoptions,
	textformatting,
	textalignment,
	layouttemplate,
	mediaoptions,
	pageoptions,
	uses,
	strikethrough,
	nowrap,
	insertwindow,
	loremipsum,
	border,
	dropshadow,
	digitalclock
}